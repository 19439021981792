import React from 'react'
import { graphql, withPrefix } from 'gatsby'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import Card from '../components/CardProduct'
import Helmet from 'react-helmet'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import Img from 'gatsby-image'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const DivBgImg2Parent = styled.div`
  width: 300px;
  height: 300px;
  margin: 10px;
  overflow: hidden;
`
const BgImg2 = styled(Img) `
height: 100%;
width: 100%;
line-height: 300px;
text-align: center;
.span {
display: inline-block;
vertical-align: middle;
line-height: normal;
}
:hover {
    -webkit-transform: scale(1.5,1.5);
    -webkit-transition: all 0.3s ease-in-out;
}
`

const FlexContainer = styled.div`
  margin-top: 50px;
  display: flex;
  font: 25px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const TextDiv = styled.div`
  padding-top: 20px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 20pt;
  font-weight:  bold;
`

const Index = ({ data }) => {

    const products = data.allContentfulMerchandiseCategory.edges
    return (
        <Layout>
            <SEO />
            {(
                <Helmet>
                    <title>Products</title>
                </Helmet>
            )}
            <TextDiv className="accentColor">
                <span> Products </span>
            </TextDiv>
            <FlexContainer>
                {products.map(({ node: post }) => (
                    <div style={{ textAlign:"center"}}>
                        <DivBgImg2Parent  >
                            <a href={withPrefix(`/product/${post.slug}`)}>
                                <BgImg2 fluid={post.heroImagge.fluid} backgroundColor={'#eeeeee'}  ></BgImg2>
                            </a>
                        
                        </DivBgImg2Parent>
                        {/*<span style={{ fontSize: "1.2em", fontWeight: "bold" }} > {post.name} </span> */}
                    </div>
                ))}
            </FlexContainer>
        </Layout>
    )
}
/*
    <Container>
      {isFirstPage ? (
        <CardList>
          <Card {...featuredPost} featured />
          {posts.slice(1).map(({ node: post }) => (
            <Card key={post.order} {...post} />
          ))}
        </CardList>
      ) : (
        <CardList>
          {posts.map(({ node: post }) => (
            <Card key={post.order} {...post} />
          ))}
        </CardList>
      )}
    </Container>
        <Pagination context={pageContext} />
    */
export const query = graphql`
  query TQuery {
  allContentfulMerchandiseCategory(sort: { fields: order }) {
    edges {
      node {
        id
        heroImagge {
          fluid(maxWidth: 1800, , maxHeight: 800) {
            ...GatsbyContentfulFluid
          }
        }
        name
        slug
      }
    }
  }
}
`


export default Index
